import React from 'react';
import { Link, useParams } from 'react-router-dom';
import data from '../../data/data.json';

const WeekList = () => {
  const { phase } = useParams();
  const phaseData = data.filter(item => item.phase === Number(phase));
  const uniqueWeeks = [...new Set(phaseData.map(item => item.week))];

  return (
    <div className="nav-list">
      <h2>Weeks for Phase: {phase}</h2>
      <ul>
        {uniqueWeeks.map(week => (
          <li key={`week_${week}`}>
            <Link to={`/${phase}/${week}`} className="nav-button">Week {week}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WeekList;