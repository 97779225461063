// Workout.js
import React, {useRef} from 'react';
import { useParams } from 'react-router-dom';
import data from '../../data/data.json';
import WorkoutCard from './WorkoutCard';
import './Workout.css';
import {SwipeableList, SwipeableListItem} from "react-swipeable-list"
import 'react-swipeable-list/dist/styles.css'
import beep_sound from "../../audio/beep2.wav"

const Workout = () => {
  const { phase, week, day } = useParams();
  const formattedDay = day.replace(/-/g, ' ');
  const audioRef = useRef(null);

  const workoutData = data.filter(
    item => item.phase === Number(phase) && item.week === Number(week) && item.day === formattedDay
  );

  return (
    <div className="workout-list">
      <h2>Workout for Phase: {phase}, Week: {week}, Day: {formattedDay}</h2>
        <audio ref={audioRef} src={beep_sound} preload="auto"/>
      <SwipeableList>
      {workoutData.map((workout, index) => (
        <WorkoutCard key={index} workout={workout} audioRef={audioRef} />
      ))}
      </SwipeableList>
    </div>
  );
};

export default Workout;