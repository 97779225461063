// WorkoutCard.js
import React, { useRef, useState, useEffect } from 'react';
import './WorkoutCard.css';
import {LeadingActions, SwipeableListItem, TrailingActions, SwipeAction} from "react-swipeable-list";
import 'react-swipeable-list/dist/styles.css'

const WorkoutCard = ({ workout, audioRef }) => {
  const [completed, setCompleted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(workout.time || 0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    if(workout.time) {
      if (isTimerRunning && timeLeft > 0) {
        timerRef.current = setInterval(() => {
          setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);
      } else {
        clearInterval(timerRef.current);
        if (timeLeft === 0) {
          setCompleted(true);
          //audioRef.current.volume = 0.1;
            try{
                audioRef.current.play();
            }
            catch(e){

            }


        }
      }

      return () => {
        clearInterval(timerRef.current);
      };
    }
  }, [isTimerRunning, timeLeft]);

  const leadingActions = () => (
    <LeadingActions>
      <SwipeAction
          onClick={() => {
            if(workout.time) {
              setIsTimerRunning(!isTimerRunning);
            } else {
              setCompleted(true);
            }
          }}>
        <div className="action-done">
          {workout.time? (
              isTimerRunning ? (
                  "Pause Timer"
              ) : (
                  "Start Timer"
              )
          ) : ( 'Done!' )}

        </div>
      </SwipeAction>
    </LeadingActions>
  );

   const trailingActions_Completed = () => (
    <TrailingActions>
      <SwipeAction
          onClick={() => {
            if(workout.time) {
              setIsTimerRunning(false);
              setTimeLeft(workout.time);
            }
            setCompleted(false);
          }}>
        <div className="action-not-done">
          Oops, Not Done
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  const renderPlatesTable = plates => {
    return (
      <table className="plates-table">
        <thead>
          <tr>
            <th>45</th>
            <th>35</th>
            <th>25</th>
            <th>10</th>
            <th>5</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{plates['45'] || 0}</td>
            <td>{plates['35'] || 0}</td>
            <td>{plates['25'] || 0}</td>
            <td>{plates['10'] || 0}</td>
            <td>{plates['5'] || 0}</td>
          </tr>
        </tbody>
      </table>
    );
  };


  return (
      <>
          {completed ?
            (
              <SwipeableListItem
                trailingActions={trailingActions_Completed()}
                threshold={0.5}
              >

                <div className="workout-card completed">
                  <h3 className="card-title">{workout.movement} ... DONE!</h3>
                </div>
              </SwipeableListItem>
            ) : (
                <SwipeableListItem
                    leadingActions={leadingActions()}
                    threshold={0.5}
                >
                  <div className="workout-card">
                    <div className="card-content">

                      <h3 className="card-title">{workout.movement}</h3>
                      {workout.reps && <p>Reps: {workout.reps}</p>}
                      {workout.actual_weight ? (
                        <p>Weight: {workout.actual_weight}</p>
                      ) : workout.ideal_weight ? (
                        <p>Weight (Ideal): {workout.ideal_weight}</p>
                      ) : null}
                      {workout.plates && (
                        <div>
                          <p>Plates:</p>
                          {renderPlatesTable(workout.plates)}
                        </div>
                      )}
                    </div>

                    {workout.time && (
                        <div className="time">
                          {timeLeft}
                        </div>
                    )}

                  </div>
                </SwipeableListItem>
              )}
      </>

  );
};

export default WorkoutCard;