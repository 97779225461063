import React from 'react';
import { Link, useParams } from 'react-router-dom';
import data from '../../data/data.json';

const DayList = () => {
  const { phase, week } = useParams();
  const phaseWeekData = data.filter(item => item.phase === Number(phase) && item.week === Number(week));
  const uniqueDays = [...new Set(phaseWeekData.map(item => item.day))];

  const createUrlFriendlyDay = day => {
    return day.replace(/\s+/g, '-');
  };

  return (
    <div className="nav-list">
      <h2>Days for Phase: {phase}, Week: {week}</h2>
      <ul>
        {uniqueDays.map(day => {
          const urlFriendlyDay = createUrlFriendlyDay(day);
          return (
            <li key={`day_${urlFriendlyDay}`}>
              <Link to={`/${phase}/${week}/${urlFriendlyDay}`} className="nav-button">{day}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DayList;