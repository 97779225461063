import React from 'react';
import {Link } from 'react-router-dom';
import data from "../../data/data.json";

const PhaseList = () => {
    const unique_phases = [...new Set(data.map(item => item.phase))];

    return (
        <div className="nav-list">
            <h2>Select A Phase:</h2>
            <ul>
                { unique_phases.map(phase => (
                    <li key={`phase_${phase}`}>
                        <Link to={`/${phase}`} className="nav-button">Phase {phase}</Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default PhaseList;