import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PhaseList from './components/PhaseList/PhaseList';
import WeekList from './components/WeekList/WeekList';
import DayList from './components/DayList/DayList';
import Workout from './components/Workout/Workout';
import "./App.css"

const App = () => {
  const [audioInitialized, setAudioInitialized] = useState(false);

  useEffect(() => {
    requestAudioPermission();
  }, []);

  const requestAudioPermission = () => {
    if (typeof AudioContext !== 'undefined' || typeof webkitAudioContext !== 'undefined') {
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      const audioContext = new AudioContext();

      if (audioContext.state === 'suspended') {
        audioContext.resume().then(() => {
          setAudioInitialized(true);
        });
      } else {
        setAudioInitialized(true);
      }
    }
  };

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<PhaseList />} />
          <Route path="/:phase" element={<WeekList />} />
          <Route path="/:phase/:week" element={<DayList />} />
          <Route path="/:phase/:week/:day" element={<Workout />} />
          <Route path="*" element={<PhaseList />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;